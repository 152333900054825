<template>
<div id="page" class="expense-type-ma-in-base">
  <el-row class="rmic-form-row">
    <el-button type="primary" class="rmicfr-add-btn" @click="handleAdd">添加类型</el-button>
  </el-row>
  <el-table :data="table.data" v-loading="table.loading" header-cell-class-name="table-cell">
    <el-table-column type="index" width="80" label="序号" align="center" />
    <el-table-column prop="txt_compensation_type_name" label="费用类型名称" align="center" />
    <el-table-column prop="dt_create" label="创建时间" align="center">
      <template #default="scope">{{ scope.row.dt_create||"- -" }}</template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="table-option">
      <template #default="scope">
        <div class="table-icon" @click="handleEdit(scope.row)">编辑</div>
        <div class="table-icon" v-if="scope.row.is_disabled==null" @click="handleDelete(scope.row)">删除</div>
      </template>
    </el-table-column>
  </el-table>
  <pagination ref="pageRef" :total="table.total" @sizeChange="(e) => {form.limit=e;getList()}" @pageChange="(e) => {form.page=e;getList()}" />
</div>
</template>

<script>
// 基础信息管理 - 费用类型
import { Pagination } from "components";
import { ElMessage, ElMessageBox} from 'element-plus';
import { reactive, toRefs, onMounted, ref } from 'vue';
import { getExpenseTypes, addExpenseType, editExpenseType, deleteExpenseType } from "api/apis.js";
export default {
  components: {
    Pagination
  },
  setup() {
    const pageRef = ref(null);
    const state = reactive({
      form: {
        page: 1,
        limit: 10
      },
      table: {
        data: [],
        loading: false,
        total: 0
      },
      dialog: {
        show: false,
        data: {}
      }
    });

    onMounted(() => {
      getList();
    })

    // 编辑类型
    const handleEdit = item => {
      let inputValue;
      ElMessageBox.prompt('费用类型名称', '编辑类型', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        inputValue: item.txt_compensation_type_name,
        inputPattern: /^.+$/,
        inputErrorMessage: "请填写费用类型名称"
      }).then(({ value }) => {
        inputValue = value;
        editExpenseType({
          t_compensation_type_id: item.t_compensation_type_id,
          txt_compensation_type_name: inputValue
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("操作成功");
            getList();
          } else {
            ElMessage.error("操作失败" + response.msg);
          }
        }).catch(e => {
          console.error(e);
        })
      }).catch(() => {})
    };

    // 添加类型
    const handleAdd = () => {
      let inputValue;
      ElMessageBox.prompt('费用类型名称', '添加类型', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        inputPattern: /^.+$/,
        inputErrorMessage: "请填写费用类型名称"
      }).then(({ value }) => {
        inputValue = value;
        addExpenseType({
          txt_compensation_type_name: inputValue
        }).then(response => {
          if (response.code == 200) {
            ElMessage.success("操作成功");
            getList();
          } else {
            ElMessage.error("操作失败" + response.msg);
          }
        }).catch(e => {
          console.error(e);
        })
      }).catch(() => {})
    };

    // 删除
    const handleDelete = row => {
      ElMessageBox.confirm(`删除后不可恢复，请确认是否删除类型【${row.txt_compensation_type_name}】？`, "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消"
      }).then(() => {
        state.table.loading = true;
        deleteExpenseType({ t_compensation_type_id: row.t_compensation_type_id }).then(response => {
          if (response.code == 200) {
            ElMessage.success("删除成功");
            getList();
          } else {
            ElMessage.error("删除失败：" + response.msg);
          }
        }).catch(e => {
          console.error(e);
        }).finally(() => {
          state.table.loading = false;
        })
      })
    };

    // 获取列表数据
    const getList = () => {
      state.table.loading = true;

      getExpenseTypes(state.form).then(response => {
        if (response.code == 200) {
          state.table.data = response.data;
          state.table.total = response.total;
        } else {
          ElMessage.error(response.msg);
        }
      }).catch(e => {
        console.error(e);
      }).finally(() => {
        state.table.loading = false;
      })
    };

    return {
      ...toRefs(state),
      getList,
      pageRef,
      handleAdd,
      handleEdit,
      handleDelete
    }
  }
}
</script>

<style lang="scss" scoped>
.expense-type-ma-in-base {
  .rmic-form-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 10px;

    .el-button {
      background-color: #8a88c3;
      border-radius: 14px;
      border-color: #8a88c3;
      font-weight: bold;
    }
  }

  .el-table {
    height: calc(100% - 100px);
  }
}
</style>
